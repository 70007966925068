import { atom } from "jotai";
import { careerplannerUserAtom, dokgabiUserAtom } from "../Atoms/PublicAtom";
import {
  errorModalAtom,
  initializeTossPaymentsModal,
  successModalAtom,
  tossPaymentsModalAtom,
  TossPaymentsModalProps,
} from "../Atoms/RootAtom";
import {
  insertPayLog,
  updateCpOrganizerPlan,
  updatePlan,
  updateUsage,
} from "../Queries/UserQueries";
import { getCookie } from "../Utils/CookieUtil";
import { ResponseStatus } from "../Enum/NetworkEnum";
import { getVerifyRefreshToken } from "../Queries/AuthQueries";

export const handleDokgabiExtraAtom = atom(
  null,
  async (
    get,
    set,
    tossPaymentsState: TossPaymentsModalProps,
    response: any
  ) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const result = await updateUsage(
        accessCookie ?? "",
        get(dokgabiUserAtom)?.id ?? "",
        tossPaymentsState.asset ?? 0,
        tossPaymentsState.chat ?? 0
      );

      if (result === ResponseStatus.FORBIDDEN) {
        const newAccessToken = await getVerifyRefreshToken(
          refreshCookie,
          "dokgabi"
        );
        if (
          typeof newAccessToken === "string" &&
          newAccessToken !== ResponseStatus.ERROR
        ) {
          const againUpdatePlan = await updateUsage(
            newAccessToken,
            get(dokgabiUserAtom)?.id ?? "",
            tossPaymentsState.asset ?? 0,
            tossPaymentsState.chat ?? 0
          );

          if (againUpdatePlan === true) {
            const insertPayLog2 = await insertPayLog(newAccessToken, {
              price: tossPaymentsState.price,
              order_id: tossPaymentsState.orderId,
              purchase_name: tossPaymentsState.orderName,
              user_id: get(dokgabiUserAtom)?.id ?? "",
              payment_key: response.paymentKey ?? "",
              payment_type: response.type ?? "",
              site_name: "dokgabi",
            });

            if (insertPayLog2 === true) {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(successModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 성공하셨습니다.",
              //   title: "결제 성공",
              // });
            } else {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(errorModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 실패하셨습니다.",
              //   title: "결제 실패",
              // });
            }
          } else {
            set(tossPaymentsModalAtom, initializeTossPaymentsModal);

            // set(errorModalAtom, {
            //   state: true,
            //   event: null,
            //   eventText: "",
            //   redirectUrl: "",
            //   text: "결제에 실패하셨습니다.",
            //   title: "결제 실패",
            // });
          }
        }
      } else if (result === true) {
        const insertPayLog1 = await insertPayLog(accessCookie ?? "", {
          price: tossPaymentsState.price,
          order_id: tossPaymentsState.orderId,
          purchase_name: tossPaymentsState.orderName,
          user_id: get(dokgabiUserAtom)?.id ?? "",
          payment_key: response.paymentKey ?? "",
          payment_type: response.type ?? "",
          site_name: "dokgabi",
        });

        if (insertPayLog1 === true) {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(successModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 성공하셨습니다.",
          //   title: "결제 성공",
          // });
        }
      } else {
        set(tossPaymentsModalAtom, initializeTossPaymentsModal);

        // set(errorModalAtom, {
        //   state: true,
        //   event: null,
        //   eventText: "",
        //   redirectUrl: "",
        //   text: "결제에 실패하셨습니다.",
        //   title: "결제 실패",
        // });
      }
    }
  }
);

export const handleDokgabiSinglePaymentAtom = atom(
  null,
  async (
    get,
    set,
    tossPaymentsState: TossPaymentsModalProps,
    response: any
  ) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const result = await updatePlan(
        accessCookie ?? "",
        get(dokgabiUserAtom)?.id ?? "",
        tossPaymentsState.planName ?? "",
        tossPaymentsState.month ?? 0
      );

      if (result === ResponseStatus.FORBIDDEN) {
        const newAccessToken = await getVerifyRefreshToken(
          refreshCookie,
          "dokgabi"
        );

        if (
          typeof newAccessToken === "string" &&
          newAccessToken !== ResponseStatus.ERROR
        ) {
          const againUpdatePlan = await updatePlan(
            newAccessToken,
            get(dokgabiUserAtom)?.id ?? "",
            tossPaymentsState.planName ?? "",
            tossPaymentsState.month ?? 0
          );

          if (againUpdatePlan === true) {
            const insertPayLog2 = await insertPayLog(newAccessToken, {
              price: tossPaymentsState.price,
              order_id: tossPaymentsState.orderId,
              purchase_name: tossPaymentsState.orderName,
              user_id: get(dokgabiUserAtom)?.id ?? "",
              payment_key: response.paymentKey ?? "",
              payment_type: response.type ?? "",
              site_name: "dokgabi",
            });

            if (insertPayLog2 === true) {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(successModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 성공하셨습니다.",
              //   title: "결제 성공",
              // });
            } else {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(errorModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 실패하셨습니다.",
              //   title: "결제 실패",
              // });
            }
          } else {
            set(tossPaymentsModalAtom, initializeTossPaymentsModal);

            // set(errorModalAtom, {
            //   state: true,
            //   event: null,
            //   eventText: "",
            //   redirectUrl: "",
            //   text: "결제에 실패하셨습니다.",
            //   title: "결제 실패",
            // });
          }
        } else {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(errorModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 실패하셨습니다.",
          //   title: "결제 실패",
          // });
        }
      } else if (result === true) {
        const insertPayLog1 = await insertPayLog(accessCookie ?? "", {
          price: tossPaymentsState.price,
          order_id: tossPaymentsState.orderId,
          purchase_name: tossPaymentsState.orderName,
          user_id: get(dokgabiUserAtom)?.id ?? "",
          payment_key: response.paymentKey ?? "",
          payment_type: response.type ?? "",
          site_name: "dokgabi",
        });

        if (insertPayLog1 === true) {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(successModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 성공하셨습니다.",
          //   title: "결제 성공",
          // });
        }
      } else {
        set(tossPaymentsModalAtom, initializeTossPaymentsModal);

        // set(errorModalAtom, {
        //   state: true,
        //   event: null,
        //   eventText: "",
        //   redirectUrl: "",
        //   text: "결제에 실패하셨습니다.",
        //   title: "결제 실패",
        // });
      }
    }
  }
);

export const handleCareerplannerSinglePaymentAtom = atom(
  null,
  async (
    get,
    set,
    tossPaymentsState: TossPaymentsModalProps,
    response: any
  ) => {
    const refreshCookie = getCookie(process.env.REACT_APP_CP_REFRESH_COOKIE_ID);
    const accessCookie = getCookie(process.env.REACT_APP_CP_ACCESS_COOKIE_ID);

    if (refreshCookie && accessCookie) {
      const result = await updateCpOrganizerPlan(
        accessCookie ?? "",
        get(careerplannerUserAtom)?.id ?? "",
        "organization",
        tossPaymentsState.people ?? 0,
        tossPaymentsState.organizationName ?? ""
      );

      if (result === ResponseStatus.FORBIDDEN) {
        const newAccessToken = await getVerifyRefreshToken(
          refreshCookie,
          "careerplanner"
        );

        if (
          typeof newAccessToken === "string" &&
          newAccessToken !== ResponseStatus.ERROR
        ) {
          const againUpdatePlan = await updateCpOrganizerPlan(
            newAccessToken,
            get(careerplannerUserAtom)?.id ?? "",
            "organization",
            tossPaymentsState.people ?? 0,
            tossPaymentsState.organizationName ?? ""
          );

          if (againUpdatePlan.results === true) {
            const insertPayLog2 = await insertPayLog(newAccessToken, {
              price: tossPaymentsState.price,
              order_id: tossPaymentsState.orderId,
              purchase_name: tossPaymentsState.orderName,
              user_id: get(careerplannerUserAtom)?.id ?? "",
              payment_key: response.paymentKey ?? "",
              payment_type: response.paymentType ?? "",
              site_name: "careerplanner",
            });

            if (insertPayLog2 === true) {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              set(successModalAtom, {
                state: true,
                event: null,
                eventText: "",
                redirectUrl: "",
                text: `그룹 코드가 생성 되었습니다.\n${
                  againUpdatePlan.group_code
                }\n로 ${
                  againUpdatePlan.state === 1
                    ? "그룹 코드 생성이 되었습니다."
                    : "인원 수가 업데이트 되었습니다."
                }\n초대할 인원에게 해당 그룹 코드를 전달해주세요.`,
                title: "그룹 코드 생성 완료",
              });
            } else {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(errorModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 실패하셨습니다.",
              //   title: "결제 실패",
              // });
            }
          } else {
            set(tossPaymentsModalAtom, initializeTossPaymentsModal);

            // set(errorModalAtom, {
            //   state: true,
            //   event: null,
            //   eventText: "",
            //   redirectUrl: "",
            //   text: "결제에 실패하셨습니다.",
            //   title: "결제 실패",
            // });
          }
        } else {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(errorModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 실패하셨습니다.",
          //   title: "결제 실패",
          // });
        }
      } else if (result.results === true) {
        const insertPayLog1 = await insertPayLog(accessCookie ?? "", {
          price: tossPaymentsState.price,
          order_id: tossPaymentsState.orderId,
          purchase_name: tossPaymentsState.orderName,
          user_id: get(careerplannerUserAtom)?.id ?? "",
          payment_key: response.paymentKey ?? "",
          payment_type: response.paymentType ?? "",
          site_name: "careerplanner",
        });

        if (insertPayLog1 === true) {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          set(successModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: `그룹 코드가 생성 되었습니다.\n${result.group_code}\n로 ${
              result.state === 1
                ? "그룹 코드 생성이 되었습니다."
                : "인원 수가 업데이트 되었습니다."
            }\n초대할 인원에게 해당 그룹 코드를 전달해주세요.`,
            title: "그룹 코드 생성 완료",
          });
        }
      } else {
        set(tossPaymentsModalAtom, initializeTossPaymentsModal);
      }
    }
  }
);
