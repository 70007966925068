import { atom } from "jotai";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
  paymentTypeAtom,
} from "../Atoms/PublicAtom";
import { errorModalAtom } from "../Atoms/RootAtom";

export const handleCpPaymentClickAtom = atom(null, (get, set, { navigate }) => {
  const cpUser = get(careerplannerUserAtom);

  if (cpUser) {
    set(paymentTypeAtom, "careerplanner");
    navigate("/");
  } else
    set(errorModalAtom, {
      state: true,
      event: () =>
        (window.location.href = process.env.REACT_APP_CP_LOGIN_ADDRESS),
      eventText: "로그인 하러가기",
      redirectUrl: "",
      text: "커리어 플래너를 로그인 후에 다시 와주세요.",
      title: "커리어플래너 로그인 후 이용 가능",
    });
});

export const handleDgPaymentClickAtom = atom(null, (get, set, { navigate }) => {
  const dgUser = get(dokgabiUserAtom);

  if (dgUser) {
    set(paymentTypeAtom, "dokgabi");
    navigate("/");
  } else
    set(errorModalAtom, {
      state: true,
      event: () =>
        (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
      eventText: "로그인 하러가기",
      redirectUrl: "",
      text: "챗봇 빌더에 로그인 후에 다시 와주세요.",
      title: "챗봇 빌더 로그인 후 이용 가능",
    });
});
