import {
    Button,
    ButtonGroup,
    IconButton,
    styled as muiStyled,
  } from "@mui/material";
  import styled from "styled-components";
  
  export const ChatbotDesignMobileHeaderDiv = styled.div`
    width: 100%;
    height: 56px;
    background-color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    @media (min-width: 768px) {
      display: none;
    }
  `;
  
  export const ChatbotDesignHeaderDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 56px;
    background-color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 16%;
    box-sizing: border-box;
    box-shadow: 0 5px 5px #d8d8d8;
  
    @media (max-width: 768px) {
      display: none;
    }
  `;
  
  export const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 10px 20px;
    background: white;
    border-radius: 30px;
  `;
  
  export const CurrentPageButton = muiStyled(Button)({
    borderRadius: "40px",
    background: process.env.REACT_APP_MAIN_COLOR,
    padding: "0 30px",
    transition: "transform 0.3s ease",
    color: "white",
    "&:hover": {
      background: process.env.REACT_APP_MAIN_COLOR,
      transform: "scale(1.2)",
    },
  });
  
  export const ElsePageButton = muiStyled(Button)({
    borderRadius: "40px",
    padding: "10px 30px",
    transition: "transform 0.3s ease",
    color: "gray",
    "&:hover": { transform: "scale(1.2)" },
  });
  
  export const InputField = styled.input`
    flex: 1;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s ease;
    box-sizing: border-box;
    padding: 0 10px;
    border: 1px solid ${process.env.REACT_APP_MAIN_COLOR};
  
    &:focus {
      border: 1.2px solid ${process.env.REACT_APP_MAIN_COLOR};
    }
  `;
  
  export const FunctionBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    flex: 1;
    gap: 10px;
  `;
  
  export const FunctionButton = muiStyled(Button)({
    backgroundColor: process.env.REACT_APP_MAIN_COLOR,
    color: "#FFFFFF",
    borderRadius: "30px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#3A1A7A",
    },
  });
  
  export const CustomButtonGroup = muiStyled(ButtonGroup)({
    "& .MuiButtonGroup-grouped": {
      borderColor: "white",
      "&:hover": {
        borderColor: "white",
      },
    },
  });
  
  export const ExpandIconButton = muiStyled(Button)({
    backgroundColor: process.env.REACT_APP_MAIN_COLOR,
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#3A1A7A",
    },
  });
  
  export const SignupButton = muiStyled(Button)({
    backgroundColor: process.env.REACT_APP_MAIN_COLOR, // 배경색을 `${process.env.REACT_APP_MAIN_COLOR}`로 설정
    color: "#FFFFFF", // 텍스트 색상을 흰색으로 설정
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#3A1A7A", // 호버 시 배경색을 약간 어둡게
    },
  });
  