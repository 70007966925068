import React from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { successModalAtom } from "../../../Atoms/RootAtom";
import * as S from "./styled";

const SuccessModal: React.FC = () => {
  const [successState, setsuccessState] = useAtom(successModalAtom);
  const navigate = useNavigate();

  const handleClose = () => {
    setsuccessState((prev) => ({ ...prev, state: false }));
  };

  const handleRedirect = () => {
    navigate(successState.redirectUrl);
    handleClose();
  };

  return (
    <Modal
      open={successState.state}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.CustomBox>
        <CheckCircleIcon sx={{ fontSize: 50, color: "green" }} />{" "}
        {/* Success icon */}
        <Typography
          id="modal-success-title"
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
        >
          {successState.title}
        </Typography>
        {successState.text.split("\n").map((sent, index) => (
          <Typography
            key={`modal-description-success-${index}`}
            sx={{ mt: 2, fontSize: 14 }}
          >
            {sent}
          </Typography>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mx: 1,
              background: "#8f8f8f",
              "&:hover": {
                background: "#8f8f8f",
              },
            }}
          >
            닫기
          </Button>
          {successState.redirectUrl !== "" && (
            <Button
              variant="contained"
              onClick={handleRedirect}
              sx={{
                mx: 1,
                background: "#3B0095",
                "&:hover": {
                  background: "#3B0095",
                },
              }}
            >
              이동
            </Button>
          )}
        </Box>
      </S.CustomBox>
    </Modal>
  );
};

export default SuccessModal;
