import { Box, Button } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { handlePurchaseExtraDokgabiAtom } from "../ViewModels/PlanTableViewModel";
import { hashKey } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export function ExtraCharge() {
  // URL의 query parameter를 가져오기 위해 SearchParams 사용
  const searchParams = new URLSearchParams(window.location.search);
  const chattingnum = searchParams.get("chattingnum") || 0;
  const assetnum = searchParams.get("assetnum") || 0;
  const totalprice = searchParams.get("totalprice") || 0;

  const [, handlePayment] = useAtom(handlePurchaseExtraDokgabiAtom);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        style={{ padding: "20px", border: "1px solid #ddd", width: "300px" }}
      >
        <h1>추가 결제</h1>
        <p>결제할 채팅 수: {chattingnum}</p>
        <p>결제할 어셋 수: {assetnum}</p>
        <p>총 금액: {totalprice}원</p>
        <Button
          onClick={() =>
            handlePayment(
              totalprice as unknown as number,
              assetnum as unknown as number,
              chattingnum as unknown as number,
              { navigate }
            )
          }
          style={{ marginTop: "10px" }}
        >
          결제하기
        </Button>
      </div>
    </Box>
  );
}
