import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  ListItem,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import {
  careerplannerUserAtom,
  dokgabiUserAtom,
} from "../../../../Atoms/PublicAtom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import * as S from "./styled";
import { setStateDrawerAtom } from "../../../../ViewModels/HeaderViewModel";
import {
  handleCpPaymentClickAtom,
  handleDgPaymentClickAtom,
} from "../../../../ViewModels/PaymentViewModel";

export function CustomDrawer() {
  const [drawerOpen, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [user] = useAtom(dokgabiUserAtom);
  const [cpUser] = useAtom(careerplannerUserAtom);

  const [, handleCpPaymentClick] = useAtom(handleCpPaymentClickAtom);
  const [, handleDgPaymentClick] = useAtom(handleDgPaymentClickAtom);

  const navigate = useNavigate();

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClick={(e) => toggleDrawer(false, e)}
    >
      <S.CustomList
        onClick={(e) => toggleDrawer(false, e)}
        onKeyDown={(e) => toggleDrawer(false, e)}
      >
        <Box>
          {user && (
            <>
              <Typography
                fontSize={"12px"}
                color={"#8f8f8f"}
                marginBottom={"5px"}
              >
                챗봇 빌더 회원 정보
              </Typography>
              <MenuItem
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS)
                }
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    user?.image === ""
                      ? ""
                      : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 2,
                  }}
                >
                  <Typography variant="h6">{user?.id}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user?.membership}
                  </Typography>
                </Box>
              </MenuItem>
            </>
          )}

          {cpUser && (
            <>
              <Divider />
              <Typography
                fontSize={"12px"}
                color={"#8f8f8f"}
                marginBottom={"5px"}
              >
                커리어플래너 회원 정보
              </Typography>
              <MenuItem
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_CP_DASHBOARD_ADDRESS)
                }
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    cpUser?.image === ""
                      ? ""
                      : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${cpUser?.image}`
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 2,
                  }}
                >
                  <Typography variant="h6">{cpUser?.id}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {cpUser?.membership}
                  </Typography>
                </Box>
              </MenuItem>
            </>
          )}

          <Divider />
          <S.CustomListItem onClick={() => handleCpPaymentClick({ navigate })}>
            <S.CustomTypo>커리어플래너 요금제</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomListItem onClick={() => handleDgPaymentClick({ navigate })}>
            <S.CustomTypo>챗봇빌더 요금제</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
        </Box>

        {/* <Box sx={{ padding: 0, margin: 0 }}>
          <Divider />
          <S.CustomListItem onClick={toDashboard}>
            <S.CustomTypo>챗봇 만들러가기</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <Divider />
          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>가격표</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>

          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>튜토리얼</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>

          <Divider />

          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>내 프로필 설정</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>플랜 업그레이드</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>플랜 연장</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomBackgroundListItem onClick={handleLogout}>
            <Typography sx={{ fontSize: "14px", color: "white" }}>
              로그아웃
            </Typography>
          </S.CustomBackgroundListItem>
        </Box> */}
      </S.CustomList>
    </Drawer>
  );
}
