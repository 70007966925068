import {
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";
import * as S from "./styled";
import { useAtom } from "jotai";
import {
  careerplannerPeopleAtom,
  organizationNameAtom,
} from "../../../Atoms/PlanTableAtom";
import { handlePurchaseCareerPlannerSingleButtonAtom } from "../../../ViewModels/PlanTableViewModel";
import { useNavigate } from "react-router-dom";

export function CareerplannerSingle() {
  const [careerplannerPeople, setCareerplannerPeople] = useAtom(
    careerplannerPeopleAtom
  );
  const [organization, setOrganization] = useAtom(organizationNameAtom);

  const [, handlePurchase] = useAtom(
    handlePurchaseCareerPlannerSingleButtonAtom
  );

  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} key={`cp-plan`}>
          <S.PlanBox>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              <S.PlanNameBox>organization</S.PlanNameBox>
            </Stack>

            <S.PriceFullBox>
              <S.PriceBox>
                <S.PriceTypo>
                  ₩{(10000 * (careerplannerPeople ?? 1)).toLocaleString()}
                </S.PriceTypo>
              </S.PriceBox>
            </S.PriceFullBox>

            <S.PriceDescTypo>
              많은 학생들을 한번에 넣을 수 있는 그룹 코드를 함께 제공해 드려요
            </S.PriceDescTypo>

            <S.FeaturesStack direction={"column"}>
              <S.FeatureStack direction={"row"}>
                <S.FeatureTypo>한번의 결제로 1년동안 사용</S.FeatureTypo>
                <Tooltip title={"한번의 결제로 1년동안 사용"}>
                  <S.FeatureIcon />
                </Tooltip>
              </S.FeatureStack>
              <S.FeatureStack direction={"row"}>
                <S.FeatureTypo>
                  진로 관심사에 따른 학과, 대학 추천
                </S.FeatureTypo>
                <Tooltip title={"진로 관심사에 따른 학과, 대학 추천"}>
                  <S.FeatureIcon />
                </Tooltip>
              </S.FeatureStack>
              <S.FeatureStack direction={"row"}>
                <S.FeatureTypo>
                  마인드맵, 간트차트, 칸반보드 등 다양한 형태로 나만의 진로 설계
                </S.FeatureTypo>
                <Tooltip
                  title={
                    "마인드맵, 간트차트, 칸반보드 등 다양한 형태로 나만의 진로 설계"
                  }
                >
                  <S.FeatureIcon />
                </Tooltip>
              </S.FeatureStack>
              <S.FeatureStack direction={"row"}>
                <S.FeatureTypo>
                  크루를 통하여 친구들, 선생님들과의 소셜 네트워킹 제공
                </S.FeatureTypo>
                <Tooltip
                  title={
                    "크루를 통하여 친구들, 선생님들과의 소셜 네트워킹 제공"
                  }
                >
                  <S.FeatureIcon />
                </Tooltip>
              </S.FeatureStack>
            </S.FeaturesStack>

            {/* Custom Divider */}
            <S.CustomDivider />

            {/* Organization Name */}
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                기관명
              </Typography>
              <TextField
                fullWidth
                value={organization ?? ""}
                onChange={(e) => setOrganization(e.target.value || null)}
                placeholder="기관명을 입력해주세요."
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
            </Box>

            {/* 인원 수 선택 */}
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                인원 선택
              </Typography>
              <Select
                onChange={(e) =>
                  setCareerplannerPeople(
                    e.target.value === "" ? null : Number(e.target.value)
                  )
                }
                fullWidth
                value={careerplannerPeople ?? ""}
                displayEmpty
                sx={{ borderRadius: "8px" }}
              >
                <MenuItem value="" disabled>
                  기관 내의 인원을 선택해주세요.
                </MenuItem>
                {[
                  1, 4, 15, 20, 45, 50, 90, 100, 200, 300, 500, 600, 700, 1000,
                ].map((month) => (
                  <MenuItem value={month} key={month}>
                    {month}명
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <S.PurchaseButton
              variant="contained"
              onClick={() => handlePurchase({ navigate })}
            >
              구매하기
            </S.PurchaseButton>
          </S.PlanBox>
        </Grid>
      </Grid>
    </>
  );
}
