import React from "react";
import Root from "./Root";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { PlanTable } from "./Pages/PlanTable";
import { Refund } from "./Pages/Refund";
import { SuccessPage } from "./Pages/Success";
import { FailPage } from "./Pages/Fail";
import TossPaymentsModal from "./Components/Root/Modal/TossPaymentsModal";
import { ExtraCharge } from "./Pages/ExtraCharge";
const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route path="" element={<PlanTable />} />
        <Route path="extra" element={<ExtraCharge />} />
        <Route path="refund" element={<Refund />} />
        <Route path="checkout" element={<TossPaymentsModal />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="fail" element={<FailPage />} />
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;
