import React from "react";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { handleEmailModalAtom, PlansAtom } from "../../../Atoms/PlanTableAtom";
import { dokgabiUserAtom } from "../../../Atoms/PublicAtom";
import { handlePurchaseButtonAtom } from "../../../ViewModels/PlanTableViewModel";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import * as S from "./styled";

export function Subscription() {
  const [plans] = useAtom(PlansAtom);
  const [user] = useAtom(dokgabiUserAtom);
  const [, setHandleEmailModal] = useAtom(handleEmailModalAtom);

  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={12} md={6} lg={2.4} key={`plan-${index}`}>
            <S.PlanBox>
              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <S.PlanNameBox>{plan.planName}</S.PlanNameBox>
                {user !== null &&
                  user.membership.toLowerCase() ===
                    plan.planName.toLowerCase() &&
                  user.membership_end_date < new Date() && (
                    <S.UsingPlanBox>이전에 사용했던 플랜</S.UsingPlanBox>
                  )}

                {user !== null &&
                  user.membership.toLowerCase() ===
                    plan.planName.toLowerCase() &&
                  user.membership_end_date >= new Date() && (
                    <S.UsingPlanBox>현재 사용중인 플랜</S.UsingPlanBox>
                  )}
              </Stack>

              <S.PriceFullBox>
                <S.PriceBox>
                  <S.PriceTypo>₩{plan.price.toLocaleString()}</S.PriceTypo>
                  <S.MonthlyTypo>/mo</S.MonthlyTypo>
                </S.PriceBox>
                <S.PriceDurationTypo>billed monthly</S.PriceDurationTypo>
              </S.PriceFullBox>
              <S.PriceDescTypo>{plan.desc}</S.PriceDescTypo>
              <S.PurchaseButton
                variant="contained"
                // onClick={() => handlePurchase(plan.price, plan.planName)}
              >
                구매하기
              </S.PurchaseButton>
              <S.CustomDivider />
              <S.FeaturesStack direction={"column"}>
                {plan.featureList.map((feature, i) => (
                  <S.FeatureStack
                    direction={"row"}
                    key={`${plan.planName}-feature-${i}`}
                  >
                    <S.FeatureTypo>{feature.feature}</S.FeatureTypo>
                    <Tooltip title={feature.tooltip}>
                      <S.FeatureIcon />
                    </Tooltip>
                  </S.FeatureStack>
                ))}
              </S.FeaturesStack>
            </S.PlanBox>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={6} lg={2.4} key={`plan-4`}>
          <S.PlanBox>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              <S.PlanNameBox>enterprise</S.PlanNameBox>
              {user !== null &&
                user.membership.toLowerCase() === "enterprise" &&
                user.membership_end_date < new Date() && (
                  <S.UsingPlanBox>이전에 사용했던 플랜</S.UsingPlanBox>
                )}

              {user !== null &&
                user.membership.toLowerCase() === "enterprise" &&
                user.membership_end_date >= new Date() && (
                  <S.UsingPlanBox>현재 사용중인 플랜</S.UsingPlanBox>
                )}
            </Stack>
            <S.PriceFullBox>
              <S.PriceBox>
                <S.PriceTypo>Contact Sales</S.PriceTypo>
              </S.PriceBox>
            </S.PriceFullBox>
            <S.PurchaseButton
              variant="contained"
              onClick={() => setHandleEmailModal(true)}
            >
              문의하기
            </S.PurchaseButton>
          </S.PlanBox>
        </Grid>
      </Grid>
    </>
  );
}
