import { atom } from "jotai";
import { careerplannerUserAtom, dokgabiUserAtom } from "../Atoms/PublicAtom";
import { errorModalAtom, tossPaymentsModalAtom } from "../Atoms/RootAtom";
import { nanoid } from "nanoid";
import {
  careerplannerPeopleAtom,
  organizationNameAtom,
} from "../Atoms/PlanTableAtom";

const membershipRanking = ["lite", "essential", "pro", "premium"];

export const handlePurchaseExtraDokgabiAtom = atom(
  null,
  (get, set, price: number, asset: number, chatting: number, { navigate }) => {
    const user = get(dokgabiUserAtom);

    if (user) {
      set(tossPaymentsModalAtom, {
        state: true,
        price: price,
        orderId: nanoid(),
        orderName: `어셋 ${asset}개 채팅 ${chatting}개 추가 결제`,
        customerName: user.id,
        customerEmail: user.id,
        siteName: "dokgabi",
        asset: asset,
        chat: chatting,
        planName: `어셋 ${asset}개 채팅 ${chatting}개 추가 결제`,
        payType: "extra",
      });

      navigate("/checkout");
    } else {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
        eventText: "로그인 하러가기",
        redirectUrl: "",
        text: "로그인 후에 상품을 구매해주세요.",
        title: "로그인 후 이용가능",
      });
    }
  }
);

export const handlePurchaseDokgabiSingleButtonAtom = atom(
  null,
  async (
    get,
    set,
    price: number,
    month: number | undefined,
    name: string,
    { navigate }
  ) => {
    const user = get(dokgabiUserAtom);

    if (user && month) {
      const selectRank = membershipRanking.findIndex(
        (value, index) => value === name
      );
      const currentRank = membershipRanking.findIndex(
        (value, index) => value === user.membership
      );

      if (
        selectRank >= currentRank ||
        new Date(user.membership_end_date) < new Date()
      ) {
        set(tossPaymentsModalAtom, {
          state: true,
          price: price * month,
          orderId: nanoid(),
          orderName: `${name} ${month}개월`,
          customerName: user.id,
          customerEmail: user.id,
          siteName: "dokgabi",
          month: month,
          planName: name,
          payType: "single",
        });

        navigate("/checkout");
      } else {
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "만료일이 지난후에 플랜 다운그레이드가 가능합니다.",
          title: "다운그레이드 불가",
        });
      }
    } else if (user === null) {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
        eventText: "로그인 하러가기",
        redirectUrl: "",
        text: "로그인 후에 상품을 구매해주세요.",
        title: "로그인 후 이용가능",
      });
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "사용기간을 선택해 주세요.",
        title: "사용기간 선택",
      });
    }
  }
);

export const handlePurchaseCareerPlannerSingleButtonAtom = atom(
  null,
  async (get, set, { navigate }) => {
    const user = get(careerplannerUserAtom);
    const selectPeopleNum = get(careerplannerPeopleAtom);
    const organizationName = get(organizationNameAtom);

    if (user && selectPeopleNum && organizationName) {
      set(tossPaymentsModalAtom, {
        state: true,
        price: 10000 * selectPeopleNum,
        orderId: nanoid(),
        orderName: `organization ${selectPeopleNum}명`,
        customerName: user.id,
        customerEmail: user.id,
        siteName: "careerplanner",
        payType: "single",
        organizationName: organizationName,
        people: selectPeopleNum,
      });

      navigate("/checkout");
    } else if (user === null) {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_CP_LOGIN_ADDRESS),
        eventText: "로그인 하러가기",
        redirectUrl: "",
        text: "로그인 후에 상품을 구매해주세요.",
        title: "로그인 후 이용가능",
      });
    } else if (selectPeopleNum === null) {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "사용할 인원을 선택해주세요",
        title: "인원 선택",
      });
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "기관명을 입력해주세요",
        title: "기관명 입력",
      });
    }
  }
);
