import { atom } from "jotai";

export interface UserProps {
  idx: number;
  id: string;
  membership: string;
  image: string;
  nickname: string;
  membership_end_date: Date;
}

const user = {
  idx: 1,
  id: "mike@ieum.io",
  membership: "admin",
  image: "",
  nickname: "순형",
  membership_end_date: new Date("9999-12-31 00:00:00"),
};

export const dokgabiUserAtom = atom<UserProps | null>(null);

export const careerplannerUserAtom = atom<UserProps | null>(null);

export const paymentTypeAtom = atom("careerplanner");
