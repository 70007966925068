import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  styled as muiStyled,
  Typography,
} from "@mui/material";

export const CustomList = muiStyled(List)({
  width: "auto",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  padding: 0,
  margin: 0,
});

export const CustomListItem = muiStyled(ListItem)({
  cursor: "pointer",
  "&:hover": {
    background: "#ececec",
  },
});

export const CustomBackgroundListItem = muiStyled(ListItem)({
  background: process.env.REACT_APP_MAIN_COLOR,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#3A1A7A", // 호버 시 배경색을 약간 어둡게
  },
});

export const CustomTypo = muiStyled(Typography)({
  fontSize: "14px",
});

export const CustomListItemIcon = muiStyled(ListItemIcon)({
  fontSize: "14px",
});

export const MakeChatbotButton = muiStyled(Button)({
  width: "calc(100% - 20px)",
  padding: "8px",
  margin: "10px",
  boxSizing: "border-box",
  background: process.env.REACT_APP_MAIN_COLOR,
  "&:hover": {
    background: process.env.REACT_APP_MAIN_COLOR,
  },
});

export const CustomMyChatbotListBox = muiStyled(Box)({
  maxHeight: "calc(100vh - 520px)",
  overflowY: "auto",
  boxSizing: "border-box",
  padding: "0 16px",
});

export const CustomMyChatbotTypo = muiStyled(Typography)({
  padding: "10px 16px 0 16px",
  fontSize: 12,
  fontWeight: "bold",
});

export const MyChatbotButton = muiStyled(Button)({
  width: "100%",
  display: "flex",
  justifyContent: "start",
  padding: "10px 20px",
  color: "black",
  gap: "16px",
});
