import { Button, Menu, styled as muiStyled } from "@mui/material";
import styled from "styled-components";

export const HeaderMobileContainer = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  background: white;
`;

export const HeaderInnerContainer = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border-bottom:1px solid #ececec;

  @media (max-width: 768px) {
    display: none;
  }
`;

interface HeaderInnerInnerContainerProps {
  gap: string;
}

export const HeaderInnerInnerContainer = styled.div<HeaderInnerInnerContainerProps>`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.gap};
`;

export const LoginButton = muiStyled(Button)({
  borderColor: "#D3D3D3",
  color: "#000000",
  fontWeight: "bold",
  "&:hover": {
    borderColor: "#B0B0B0",
  },
});

export const SignupButton = muiStyled(Button)({
  backgroundColor: process.env.REACT_APP_MAIN_COLOR, // 배경색을 `${process.env.REACT_APP_MAIN_COLOR}`로 설정
  color: "#FFFFFF", // 텍스트 색상을 흰색으로 설정
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "#3A1A7A", // 호버 시 배경색을 약간 어둡게
  },
});

export const CustomMenu = muiStyled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12, // 모서리를 둥글게 설정
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)", // 그림자 설정
    marginTop: theme.spacing(2),
    minWidth: 220, // 최소 너비 설정
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      width: 36,
      height: 36,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(2),
    },
  },
  "& .MuiMenu-list": {
    padding: "8px 0", // 패딩 설정
  },
  "& .MuiMenuItem-root": {
    padding: "10px 16px", // 메뉴 항목 패딩 설정
    "& .MuiListItemIcon-root": {
      minWidth: 40, // 아이콘과 텍스트 정렬
    },
  },
}));
