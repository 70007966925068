import { atom } from "jotai";
import { deleteAuthCookies, deleteCookie } from "../Utils/CookieUtil";
import { dokgabiUserAtom } from "../Atoms/PublicAtom";
import {
  drawerOpenAtom,
  pricingAnchorElAtom,
  profileAnchorElAtom,
} from "../Atoms/HeaderAtom";

export const handleProfileMenuOpenAtom = atom(
  (get) => Boolean(get(profileAnchorElAtom)),
  (get, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(profileAnchorElAtom, event.currentTarget);
  }
);

export const handleProfileMenuCloseAtom = atom(
  (get) => get(profileAnchorElAtom),
  (get, set) => set(profileAnchorElAtom, null)
);

export const handlePricingMenuOpenAtom = atom(
  (get) => Boolean(get(pricingAnchorElAtom)),
  (get, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(pricingAnchorElAtom, event.currentTarget);
  }
);

export const handlePricingMenuCloseAtom = atom(
  (get) => get(pricingAnchorElAtom),
  (get, set) => set(pricingAnchorElAtom, null)
);

export const handleLogoutItemClickAtom = atom(null, (get, set) => {
  deleteAuthCookies();
  set(dokgabiUserAtom, null);
  window.location.href = process.env.REACT_APP_DOKGABI_HOME_ADDRESS;
});

export const handleNoticeWhenServiceIsInDevelopAtom = atom(
  null,
  (get, set) => null
);

export const toDashboardAtom = atom(
  null,
  (get, set) =>
    (window.location.href = process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS)
);

export const setStateDrawerAtom = atom(
  (get) => get(drawerOpenAtom),
  (get, set, open: boolean, event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    set(drawerOpenAtom, open);
  }
);
