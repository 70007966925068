import styled from "styled-components";

export const FullContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  display: block;
  min-height: 100%;
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);
  margin-top: 56px;
  margin-left: 0px;
`;
