import React from "react";
import { Box, Typography, Link, Divider, Stack } from "@mui/material";

export function Footer() {
  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Stack direction={"column"} sx={{ width: "60%" }}>
        <Stack direction="row">
          <Box
            component={"img"}
            src="ieum_logo.png"
            sx={{
              width: "25px",
              height: "25px",
              paddingRight: "20px",
              borderRight: "1px solid #ececec",
            }}
          />
          <Stack
            direction={"column"}
            sx={{
              flex: 5,
              paddingLeft: "20px",
              borderRight: "1px solid #ececec",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              24th floor of the downtown airport tower, 36, Teheran-ro 87-gil,
              Gangnam-gu, Seoul
            </Typography>
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              © Copyright 2024 ieum interactive co., Ltd - All Rights Reserved
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              flex: 5,
              paddingLeft: "20px",
              borderRight: "1px solid #ececec",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              Company Registration Number : 238-87-01231 Mail order sales
            </Typography>
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              registration number : No. 2024-Seoul Gangnam-02747
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ marginTop: "5px" }}>
          <Stack
            direction={"column"}
            sx={{
              flex: 2,
              borderRight: "1px solid #ececec",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              A personal information protection manager : JANGHUN JEONG
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              flex: 1,
              borderRight: "1px solid #ececec",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              Tel : +82-2-523-7727
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              flex: 1,
              borderRight: "1px solid #ececec",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              Business Name : Epreep Inc.
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              flex: 1,
              borderRight: "1px solid #ececec",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              CEO : OKHEE KWAK
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ marginTop: "5px", marginBottom: "10px" }}>
          <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
            Consent to overseas transfer of personal information
          </Typography>
          <Stack
            direction={"column"}
            sx={{
              flex: 1,
              borderRight: "1px solid #ececec",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              (window.location.href = "https://epreep.com/terms.html")
            }
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              Terms Conditions
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              flex: 1,
              borderRight: "1px solid #ececec",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              (window.location.href = "https://epreep.com/privacy.html")
            }
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              privacy policy
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              flex: 2,
              borderRight: "1px solid #ececec",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#8f8f8f" }}>
              Personal Information Collection and Usage Agreement
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
