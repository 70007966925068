import { atom } from "jotai";

export interface EmailModalProps {
  name: string;
  company: string;
  email: string;
  tel: string;
  message: string;
}

export const emailValueAtom = atom<EmailModalProps>({
  name: "",
  company: "",
  email: "",
  tel: "",
  message: "",
});
