import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import * as S from "./styled";
import { handleEmailModalAtom } from "../../../Atoms/PlanTableAtom";
import { Close } from "@mui/icons-material";
import {
  handleEmailValueAtom,
  hanldeOnSubmitAtom,
  initialzeAtom,
} from "../../../ViewModels/EmailViewModel";

const EmailModal: React.FC = () => {
  const [emailModalState, setemailModalState] = useAtom(handleEmailModalAtom);
  const [emailValue, handleEmailValue] = useAtom(handleEmailValueAtom);
  const [, hanldeOnSubmit] = useAtom(hanldeOnSubmitAtom);
  const [, initialze] = useAtom(initialzeAtom);

  const handleClose = () => {
    setemailModalState((prev) => false);
    initialze();
  };

  return (
    <Modal
      open={emailModalState}
      onClose={handleClose}
      aria-labelledby="modal-email-title"
      aria-describedby="modal-email-description"
    >
      <S.CustomBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography>문의하기</Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />
          </Grid>

          {/* Name Field */}
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              label="성함"
              fullWidth
              variant="outlined"
              value={emailValue.name}
              onChange={(e) => handleEmailValue("name", e.target.value)}
            />
          </Grid>

          {/* Email Address Field */}
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              label="회사 이름"
              fullWidth
              variant="outlined"
              value={emailValue.company}
              onChange={(e) => handleEmailValue("company", e.target.value)}
            />
          </Grid>

          {/* Inquiry Type Select */}
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              label="이메일"
              fullWidth
              variant="outlined"
              value={emailValue.email}
              onChange={(e) => handleEmailValue("email", e.target.value)}
            />
          </Grid>

          {/* Phone Field */}
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              label="전화번호"
              fullWidth
              variant="outlined"
              value={emailValue.tel}
              onChange={(e) => handleEmailValue("tel", e.target.value)}
            />
          </Grid>

          {/* Message Field */}
          <Grid item xs={12}>
            <S.CustomTextField
              label="문의사항"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={emailValue.message}
              onChange={(e) => handleEmailValue("message", e.target.value)}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                background: process.env.REACT_APP_MAIN_COLOR,
                "&:hover": {
                  background: process.env.REACT_APP_MAIN_COLOR,
                },
              }}
              onClick={() => hanldeOnSubmit()}
            >
              문의
            </Button>
          </Grid>
        </Grid>
      </S.CustomBox>
    </Modal>
  );
};

export default EmailModal;
