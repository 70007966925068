import { errorModalAtom } from "../Atoms/RootAtom";
import { ResponseStatus } from "../Enum/NetworkEnum";
import { getVerifyRefreshToken } from "../Queries/AuthQueries";
import { deleteAuthCookies, getCookie } from "./CookieUtil";

export const handleApiResponse = async (
  siteName: string,
  refresh: string | null,
  set: any,
  fetchFunction: () => Promise<any> | any,
  errorFunction: () => any,
  successFunction: (results?: any) => any
) => {
  const results = await fetchFunction();

  if (results === ResponseStatus.UNAUTHORIZED) {
    deleteAuthCookies();

    set(errorModalAtom, {
      state: true,
      event: () =>
        (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
      eventText: "로그인",
      redirectUrl: "",
      text: "인증에 실패하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
      title: "인증 실패",
    });
    return null;
  } else if (results === ResponseStatus.FORBIDDEN) {
    if (refresh) {
      const results = await getVerifyRefreshToken(refresh, siteName);

      return results;
    } else {
      deleteAuthCookies();

      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
        eventText: "로그인",
        redirectUrl: "",
        text: "인증에 실패하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
        title: "인증 실패",
      });

      return null;
    }
  } else if (results === ResponseStatus.ERROR) {
    const result = errorFunction();
    return result;
  } else {
    const result = successFunction(results);
    return result;
  }
};

export const handleReactQueryApiResponse = async (
  siteName: string,
  fetchFunction: (access: string) => Promise<any>,
  access: string
) => {
  const refreshCookie = getCookie(
    process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
  );

  const result = await fetchFunction(access);
  if (result === ResponseStatus.UNAUTHORIZED || result === ResponseStatus.ERROR)
    return null;
  else if (result === ResponseStatus.FORBIDDEN) {
    const newAccessToken = await getVerifyRefreshToken(
      refreshCookie ?? "",
      siteName
    );

    const newResult = await fetchFunction(newAccessToken);

    if (
      newResult === ResponseStatus.UNAUTHORIZED ||
      newResult === ResponseStatus.ERROR ||
      newResult === ResponseStatus.FORBIDDEN
    )
      return null;
    else {
      return newResult;
    }
  } else {
    return result;
  }
};
