import { Box, Button, styled, TextField } from "@mui/material";

export const CustomBox = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  textAlign: "center",

  "@media (max-width: 768px)": {
    width: "90%", // 모바일에서는 너비를 90%로 설정
    padding: theme.spacing(2), // 패딩을 줄임
    boxShadow: theme.shadows[12], // 모바일에서 그림자 크기를 줄임
  },
}));

export const PayBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  textAlign: "center",
  width: "60vw",
  overflow: "hidden",
  display: "block",

  "@media (max-width: 768px)": {
    width: "90%",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[12],
  },
}));

export const payButton = styled(Button)({
  backgroundColor: process.env.REACT_APP_MAIN_COLOR, // 배경색을 `${process.env.REACT_APP_MAIN_COLOR}`로 설정
  color: "#FFFFFF", // 텍스트 색상을 흰색으로 설정
  width: "100%",
  fontSize: "1rem",
  marginBottom: "1rem",
  borderRadius: "20px",
  "&:hover": {
    backgroundColor: "#3A1A7A", // 호버 시 배경색을 약간 어둡게
  },
});

export const closeButton = styled(Button)({
  backgroundColor: "#8f8f8f",
  color: "#FFFFFF",
  fontSize: "1rem",
  width: "100%",
  borderRadius: "20px",
  "&:hover": {
    backgroundColor: "#4f4f4f",
  },
});

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    // "& fieldset": {
    //   borderColor: process.env.REACT_APP_MAIN_COLOR, // 기본 테두리 색상
    // },
    "&:hover fieldset": {
      borderColor: process.env.REACT_APP_MAIN_COLOR, // hover 시 테두리 색상
    },
    "&.Mui-focused fieldset": {
      borderColor: process.env.REACT_APP_MAIN_COLOR, // focus 시 테두리 색상
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: process.env.REACT_APP_MAIN_COLOR, // focus 시 레이블 색상
  },
});
