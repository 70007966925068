import { atom } from "jotai";

export const refundListAtom = atom<{ text: string; children: string[] }[]>([
  {
    text: "회원은 회사에 환불을 요구할 수 있습니다. 환불은 회사가 안내하는 정책 및 방법에 따라 진행됩니다.",
    children: [],
  },
  {
    text: "회사는 다음 각 호의 방식으로 환불을 진행합니다.",
    children: [
      `환불은 서비스를 이용한 일수를 제외하고 일할 계산과 초과된 사용량을 기준으로 진행됩니다. 월 기준은 30이고 월 이용료를 30으로 나눈 금액을 말합니다. 
    결제시간으로부터 15일까지는 남은 일 수에 대한 일할 계산으로 환불 처리됩니다. 16일 이후 30일 이내 사용자는 환불이 불가능합니다. 일할 계산은 카드 수수료와 위약금을 포함한
    결제금액의 20% 금액을 제외한 금액이며 남은 일 수에 대한 일할 계산으로 환불 처리됩니다. 최대 사용량은 각 플랜별 사용량에 따릅니다.
    계산 결과 환불 금액이 0원 이하일 경우, 추가 금액이 청구될 수 있습니다.
    계산 공식: [(전체결제금액) * 0.8/30] * 남은 일수 - 초과된 사용량`,
    ],
  },
]);
