import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styled";
import {
  handleEmailModalAtom,
  PlansAtom,
  singleOptionAtom,
} from "../../../Atoms/PlanTableAtom";
import { useAtom } from "jotai";
import { dokgabiUserAtom } from "../../../Atoms/PublicAtom";
import { set } from "lodash";
import { handlePurchaseDokgabiSingleButtonAtom } from "../../../ViewModels/PlanTableViewModel";

export function Single() {
  const [user] = useAtom(dokgabiUserAtom);
  const [plans] = useAtom(PlansAtom);
  const [singleOption, setSingleOption] = useAtom(singleOptionAtom);
  const [, handlePurchase] = useAtom(handlePurchaseDokgabiSingleButtonAtom);
  const [, setHandleEmailModal] = useAtom(handleEmailModalAtom);

  const navigate = useNavigate();

  const handleMonthChange = (
    event: SelectChangeEvent<number>,
    planName: string
  ) => {
    const newMonth = Number(event.target.value); // 선택된 개월 수

    setSingleOption((current) => {
      const result = current.find((option) => option.planName === planName);

      if (result) {
        // 기존 값이 있을 경우 month 업데이트
        return current.map((option) =>
          option.planName === planName ? { ...option, month: newMonth } : option
        );
      }

      // 기존 값이 없을 경우 새로 추가
      return [...current, { planName, month: newMonth }];
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={`plan-${index}`}>
            <S.PlanBox>
              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <S.PlanNameBox>{plan.planName}</S.PlanNameBox>
                {user !== null &&
                  user.membership.toLowerCase() ===
                    plan.planName.toLowerCase() &&
                  user.membership_end_date < new Date() && (
                    <S.UsingPlanBox>이전에 사용했던 플랜</S.UsingPlanBox>
                  )}

                {user !== null &&
                  user.membership.toLowerCase() ===
                    plan.planName.toLowerCase() &&
                  user.membership_end_date >= new Date() && (
                    <S.UsingPlanBox>현재 사용중인 플랜</S.UsingPlanBox>
                  )}
              </Stack>

              <S.PriceFullBox>
                <S.PriceBox>
                  <S.PriceTypo>
                    ₩
                    {(
                      plan.price *
                      (singleOption.find(
                        (option, index) => option.planName === plan.planName
                      )?.month ?? 1)
                    ).toLocaleString()}
                  </S.PriceTypo>
                </S.PriceBox>
              </S.PriceFullBox>

              <S.PriceDescTypo>{plan.desc}</S.PriceDescTypo>

              <S.FeaturesStack direction={"column"}>
                {plan.featureList.map((feature, i) =>
                  feature.value ? (
                    <S.FeatureStack
                      direction={"row"}
                      key={`${plan.planName}-feature-${i}`}
                    >
                      <S.FeatureTypo>
                        <Typography component="span" fontWeight="bold">
                          {feature.value *
                            (singleOption.find(
                              (option) => option.planName === plan.planName
                            )?.month ?? 1)}
                        </Typography>
                        개의 메시지 크레딧
                      </S.FeatureTypo>
                      <Tooltip title={feature.tooltip}>
                        <S.FeatureIcon />
                      </Tooltip>
                    </S.FeatureStack>
                  ) : (
                    <S.FeatureStack
                      direction={"row"}
                      key={`${plan.planName}-feature-${i}`}
                    >
                      <S.FeatureTypo>{feature.feature}</S.FeatureTypo>
                      <Tooltip title={feature.tooltip}>
                        <S.FeatureIcon />
                      </Tooltip>
                    </S.FeatureStack>
                  )
                )}
              </S.FeaturesStack>

              {/* 개월 수 선택 */}
              <S.CustomDivider />

              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  기간 선택
                </Typography>
                <Select
                  onChange={(e) => handleMonthChange(e, plan.planName)}
                  fullWidth
                  value={
                    singleOption.find(
                      (option) => option.planName === plan.planName
                    )?.month || "" // 값이 없을 경우 빈 문자열
                  }
                  displayEmpty
                  sx={{ borderRadius: "8px" }}
                >
                  <MenuItem value="" disabled>
                    사용할 기간을 선택해주세요.
                  </MenuItem>
                  {plan.price >= 2000000
                    ? [1, 2, 3, 4, 5].map((month) => (
                        <MenuItem value={month} key={month}>
                          {month}개월
                        </MenuItem>
                      ))
                    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                        <MenuItem value={month} key={month}>
                          {month}개월
                        </MenuItem>
                      ))}
                </Select>
              </Box>

              <S.PurchaseButton
                variant="contained"
                onClick={() =>
                  handlePurchase(
                    plan.price,
                    singleOption.find(
                      (option) => option.planName === plan.planName
                    )?.month,
                    plan.planName,
                    { navigate }
                  )
                }
              >
                구매하기
              </S.PurchaseButton>
            </S.PlanBox>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12} lg={12} key={`plan-4`}>
          <S.PlanBox>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              <S.PlanNameBox>enterprise</S.PlanNameBox>
              {user !== null &&
                user.membership.toLowerCase() === "enterprise" &&
                user.membership_end_date < new Date() && (
                  <S.UsingPlanBox>이전에 사용했던 플랜</S.UsingPlanBox>
                )}

              {user !== null &&
                user.membership.toLowerCase() === "enterprise" &&
                user.membership_end_date >= new Date() && (
                  <S.UsingPlanBox>현재 사용중인 플랜</S.UsingPlanBox>
                )}
            </Stack>
            <S.PriceFullBox>
              <S.PriceBox>
                <S.PriceTypo>Contact Sales</S.PriceTypo>
              </S.PriceBox>
            </S.PriceFullBox>
            <S.PurchaseButton
              variant="contained"
              onClick={() => setHandleEmailModal(true)}
            >
              문의하기
            </S.PurchaseButton>
          </S.PlanBox>
        </Grid>
      </Grid>
    </>
  );
}
