import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { errorModalAtom, tossPaymentsModalAtom } from "../../../Atoms/RootAtom";
import * as S from "./styled";
import {
  loadPaymentWidget,
  PaymentWidgetInstance,
} from "@tosspayments/payment-widget-sdk";
import { dokgabiUserAtom } from "../../../Atoms/PublicAtom";
import {
  handleCareerplannerSinglePaymentAtom,
  handleDokgabiSinglePaymentAtom,
} from "../../../ViewModels/TossPaymentsModalViewModel";
import { getCookie } from "../../../Utils/CookieUtil";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { Navigate, useNavigate } from "react-router-dom";

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
const customerKey = nanoid();

const TossPaymentsModal: React.FC = () => {
  const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey);

  const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] =
    useState(false);

  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance["renderPaymentMethods"]
  > | null>(null);

  const [tossPaymentsState, setTossPaymentsState] = useAtom(
    tossPaymentsModalAtom
  );
  const [, handleDokgabiSinglePayment] = useAtom(
    handleDokgabiSinglePaymentAtom
  );

  const [, handleCareerplannerSinglePayment] = useAtom(
    handleCareerplannerSinglePaymentAtom
  );

  const [user] = useAtom(dokgabiUserAtom);

  const navigate = useNavigate();

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    // ------  결제 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      "#payment-widget",
      { value: tossPaymentsState.price },
      { variantKey: "DEFAULT" }
    );

    // ------  이용약관 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    //  ------  결제 UI 렌더링 완료 이벤트 ------
    paymentMethodsWidget.on("ready", () => {
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
      isPaymentMethodsWidgetReady(true);
    });
  }, [paymentWidget]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(tossPaymentsState.price);
  }, [tossPaymentsState]);

  // useEffect(() => {
  //   if (tossPaymentsState.state) {
  //     (async () => {
  //       const paymentWidget = await loadPaymentWidget(
  //         process.env.REACT_APP_TOSS_CLIENT_KEY,
  //         process.env.REACT_APP_TOSS_CUSTOMER_KEY
  //       );
  //       const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
  //         "#payment-widget",
  //         {
  //           value: tossPaymentsState.price,
  //           currency: "KRW",
  //           country: "KR",
  //         }
  //       );
  //       paymentWidgetRef.current = paymentWidget;
  //       paymentMethodsWidgetRef.current = paymentMethodsWidget;
  //     })();
  //   }
  // }, [tossPaymentsState]);

  const whenPurcahseButtonClick = async () => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const refreshCpCookie = getCookie(
      process.env.REACT_APP_CP_REFRESH_COOKIE_ID
    );
    const accessCpCookie = getCookie(process.env.REACT_APP_CP_ACCESS_COOKIE_ID);

    if (
      tossPaymentsState.siteName === "dokgabi" &&
      (!refreshCookie || !accessCookie) // 둘 중 하나라도 null이면 return
    )
      return;

    if (
      tossPaymentsState.siteName === "careerplanner" &&
      (!refreshCpCookie || !accessCpCookie) // 둘 중 하나라도 null이면 return
    )
      return;

    try {
      const response = await paymentWidget?.requestPayment({
        orderId: tossPaymentsState.orderId,
        orderName: tossPaymentsState.orderName,
        successUrl: `${window.location.origin}/success`,
        failUrl: `${window.location.origin}/fail`,
        customerName: tossPaymentsState.customerName,
        customerEmail: tossPaymentsState.customerEmail,
      });

      // if (response?.paymentKey && response.paymentKey) {
      //   if (
      //     tossPaymentsState.siteName === "dokgabi" &&
      //     tossPaymentsState.payType === "single"
      //   ) {
      //     handleDokgabiSinglePayment(tossPaymentsState, response);
      //   } else if (
      //     tossPaymentsState.siteName === "careerplanner" &&
      //     tossPaymentsState.payType === "single"
      //   ) {
      //     handleCareerplannerSinglePayment(tossPaymentsState, response);
      //   }
      // }
    } catch (err) {
      setTossPaymentsState({
        state: false,
        price: 0,
        orderName: "",
        orderId: "",
        customerName: "",
        customerEmail: "",
        siteName: "",
        payType: "",
      });

      navigate("/");
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <S.PayBox>
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            결제 정보
          </Typography>
          <Divider sx={{ marginBottom: "16px" }} />

          {/* 상품명 */}
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="12px"
          >
            <Typography sx={{ fontWeight: "bold", color: "#555" }}>
              상품명
            </Typography>
            <Typography sx={{ color: "#000" }}>
              {tossPaymentsState.orderName}
            </Typography>
          </Box>

          {/* 상품가격 */}
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontWeight: "bold", color: "#555" }}>
              상품가격
            </Typography>
            <Typography sx={{ color: "#000" }}>
              {tossPaymentsState.price.toLocaleString()}원
            </Typography>
          </Box>
        </Box>

        {/* Toss Payments 위젯 */}
        <div id="payment-widget" />

        <div id="agreement" />

        {/* 결제 및 취소 버튼 */}
        <S.payButton
          variant="contained"
          disabled={!paymentMethodsWidgetReady}
          onClick={whenPurcahseButtonClick}
        >
          결제
        </S.payButton>
        <S.closeButton
          variant="contained"
          onClick={() => {
            setTossPaymentsState({
              state: false,
              price: 0,
              orderName: "",
              orderId: "",
              customerName: "",
              customerEmail: "",
              siteName: "",
              payType: "",
            });
            navigate("/");
          }}
        >
          취소
        </S.closeButton>
      </S.PayBox>
    </Box>
  );
};

export default TossPaymentsModal;

function usePaymentWidget(clientKey: string, customerKey: string) {
  return useQuery({
    queryKey: ["payment-widget", clientKey, customerKey],
    queryFn: () => {
      return loadPaymentWidget(clientKey, customerKey);
    },
  });
}
