import { atom } from "jotai";
import { deleteAuthCookies, getCookie } from "../Utils/CookieUtil";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
  paymentTypeAtom,
} from "../Atoms/PublicAtom";
import { errorModalAtom, initializeStateAtom } from "../Atoms/RootAtom";
import { handleApiResponse } from "../Utils/APIUtil";
import { getUserInfoWhenStart } from "../Queries/AuthQueries";

export const checkCookieAndAutoLoginAtom = atom(
  (get) => get(initializeStateAtom),
  async (get, set) => {
    const referrer = document.referrer;

    if (
      referrer.includes("dashboard.dokgabi.ai") ||
      referrer.includes("www.dokgabi.ai")
    ) {
      set(paymentTypeAtom, "dokgabi");
    } else if (
      referrer.includes("dashboard.careerplanner.dokgabi.ai") ||
      referrer.includes("careerplanner.dokgabi.ai")
    ) {
      set(paymentTypeAtom, "careerplanner");
    }

    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const errorFunction = () => {
        deleteAuthCookies();
        set(errorModalAtom, {
          state: true,
          event: () =>
            (window.location.href =
              process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
          eventText: "로그인",
          redirectUrl: "",
          text: "오류가 발생하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
          title: "오류 발생",
        });
      };

      const successFunction = (results: any) => {
        set(dokgabiUserAtom, {
          idx: results.id,
          id: results.user.username,
          image: results.profile_image ?? "",
          membership: results.membership,
          nickname: results.nickname,
          membership_end_date: new Date(results.end_date),
        });

        if (new Date() > new Date(results.end_date)) {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            text: "플랜을 갱신해주세요.\n만료되었어요.",
            title: "플랜 만료",
          });
        }
      };

      const newAccessToken = await handleApiResponse(
        "dokgabi",
        refreshCookie,
        set,
        () => getUserInfoWhenStart(accessCookie),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          "dokgabi",
          null,
          set,
          () => getUserInfoWhenStart(newAccessToken),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }

      set(initializeStateAtom, true);
    }

    const refreshCpCookie = getCookie(
      process.env.REACT_APP_CP_REFRESH_COOKIE_ID
    );

    const accessCpCookie = getCookie(process.env.REACT_APP_CP_ACCESS_COOKIE_ID);

    if (refreshCpCookie && accessCpCookie) {
      const errorFunction = () => {
        deleteAuthCookies();
        set(errorModalAtom, {
          state: true,
          event: () =>
            (window.location.href = process.env.REACT_APP_CP_LOGIN_ADDRESS),
          eventText: "로그인",
          redirectUrl: "",
          text: "오류가 발생하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
          title: "오류 발생",
        });
      };

      const successFunction = (results: any) => {
        set(careerplannerUserAtom, {
          idx: results.id,
          id: results.user.username,
          image: results.profile_image ?? "",
          membership: results.membership,
          nickname: results.nickname,
          membership_end_date: new Date(results.end_date),
        });

        if (new Date() > new Date(results.end_date)) {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            text: "플랜을 갱신해주세요.\n만료되었어요.",
            title: "플랜 만료",
          });
        }
      };

      const newAccessToken = await handleApiResponse(
        "careerplanner",
        refreshCpCookie,
        set,
        () => getUserInfoWhenStart(accessCpCookie),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          "careerplanner",
          null,
          set,
          () => getUserInfoWhenStart(newAccessToken),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }

      set(initializeStateAtom, true);
    }
  }
);
