import { atom } from "jotai";
import { EmailModalProps, emailValueAtom } from "../Atoms/EmailModalAtom";
import { errorModalAtom, successModalAtom } from "../Atoms/RootAtom";
import { sendEmailWhenQuestion } from "../Queries/UserQueries";
import { handleEmailModalAtom } from "../Atoms/PlanTableAtom";

export const initialzeAtom = atom(null, (get, set) =>
  set(emailValueAtom, {
    name: "",
    company: "",
    email: "",
    tel: "",
    message: "",
  })
);

export const handleEmailValueAtom = atom(
  (get) => get(emailValueAtom),
  (get, set, key: keyof EmailModalProps, value: string) => {
    set(emailValueAtom, (current) => ({
      ...current,
      [key]: value,
    }));
  }
);

export const hanldeOnSubmitAtom = atom(null, async (get, set) => {
  const emailValue = get(emailValueAtom);

  // 간단한 이메일 및 전화번호 정규식
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // 값이 없거나 잘못된 경우 처리
  if (!emailValue.name) {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "이름을 입력해주세요.",
      title: "이름 입력 오류",
    });
    return;
  }

  if (!emailValue.company) {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "회사를 입력해주세요.",
      title: "회사 입력 오류",
    });
    return;
  }

  if (!emailRegex.test(emailValue.email)) {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "이메일을 올바르게 입력해주세요.",
      title: "이메일 입력 오류",
    });
    return;
  }

  if (!emailValue.tel) {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "전화번호를 올바르게 입력해주세요.",
      title: "전화번호 입력 오류",
    });
    return;
  }

  if (!emailValue.message) {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "문의사항을 입력해주세요.",
      title: "문의사항 입력 오류",
    });
    return;
  }

  const result = await sendEmailWhenQuestion(emailValue);

  if (result) {
    set(handleEmailModalAtom, false);

    set(emailValueAtom, {
      name: "",
      company: "",
      email: "",
      tel: "",
      message: "",
    });

    set(successModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "문의사항이 접수 되었습니다.\n확인 후 연락드리겠습니다.",
      title: "문의사항 접수 완료",
    });
  } else {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "다시 시도해 주세요.",
      title: "네트워크 오류",
    });
  }
});
