import { useAtom } from "jotai";
import React from "react";
import {
  handleProfileMenuCloseAtom,
  handleProfileMenuOpenAtom,
} from "../../../../ViewModels/HeaderViewModel";
import { Avatar, Box, Divider, MenuItem, Typography } from "@mui/material";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
} from "../../../../Atoms/PublicAtom";
import * as S from "./styled";

export function ProfileMenu() {
  const [user] = useAtom(dokgabiUserAtom);
  const [cpUser] = useAtom(careerplannerUserAtom);

  const [profileOpen] = useAtom(handleProfileMenuOpenAtom);
  const [profileAnchorEl, profileHandleClose] = useAtom(
    handleProfileMenuCloseAtom
  );

  return (
    <S.CustomMenu
      anchorEl={profileAnchorEl}
      id="account-menu"
      open={profileOpen}
      onClose={profileHandleClose}
      onClick={profileHandleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {user && (
        <>
          <Typography fontSize={"12px"} color={"#8f8f8f"} marginBottom={"5px"}>
            챗봇 빌더 회원 정보
          </Typography>
          <MenuItem>
            <Avatar
              alt="Remy Sharp"
              src={
                user?.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 2,
              }}
            >
              <Typography variant="h6">{user?.id}</Typography>
              <Typography variant="body2" color="text.secondary">
                {user?.membership}
              </Typography>
            </Box>
          </MenuItem>
        </>
      )}

      {cpUser && (
        <>
          <Divider />
          <Typography fontSize={"12px"} color={"#8f8f8f"} marginBottom={"5px"}>
            커리어플래너 회원 정보
          </Typography>
          <MenuItem>
            <Avatar
              alt="Remy Sharp"
              src={
                cpUser?.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${cpUser?.image}`
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 2,
              }}
            >
              <Typography variant="h6">{cpUser?.id}</Typography>
              <Typography variant="body2" color="text.secondary">
                {cpUser?.membership}
              </Typography>
            </Box>
          </MenuItem>
        </>
      )}
    </S.CustomMenu>
  );
}
