import { atomWithStorage } from "jotai/utils";
import CryptoJS from "crypto-js";
import { atom } from "jotai";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encrypt = (data: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decrypt = (data: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    return null;
  }
};

// 커스텀 atomWithStorage
export const encryptedAtomWithStorage = <T>(key: string, initialValue: T) => {
  const storage = {
    getItem: (key: string) => {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? decrypt(storedValue) : initialValue;
    },
    setItem: (key: string, newValue: T) => {
      const encryptedValue = encrypt(newValue);
      sessionStorage.setItem(key, encryptedValue);
    },
    removeItem: (key: string) => {
      sessionStorage.removeItem(key);
    },
  };
  return atomWithStorage<T>(key, initialValue, storage);
};

// 커스텀 atomWithStorage
export const encryptedAtomWithLocalStorage = <T>(
  key: string,
  initialValue: T
) => {
  const storage = {
    getItem: (key: string) => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? decrypt(storedValue) : initialValue;
    },
    setItem: (key: string, newValue: T) => {
      const encryptedValue = encrypt(newValue);
      localStorage.setItem(key, encryptedValue);
    },
    removeItem: (key: string) => {
      localStorage.removeItem(key);
    },
  };

  // 초기화를 강제하기 위해 `atom` 대신 `atomWithStorage` 초기화를 한 번 호출
  const baseAtom = atom(initialValue);

  return atom(
    (get) => {
      const storedValue = storage.getItem(key);
      return storedValue ?? get(baseAtom);
    },
    (get, set, newValue: T) => {
      storage.setItem(key, newValue);
      set(baseAtom, newValue);
    }
  );
};
