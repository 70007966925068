import {
  Box,
  Button,
  Divider,
  Icon,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const PlanBox = styled(Box)({
  border: "1px solid #cfcfcf",
  background: "white",
  borderRadius: "0.4rem",
  padding: "1.6rem",
  flexGrow: 1,
  height: "100%",
  boxSizing: "border-box",
});

export const PlanNameBox = styled(Box)({
  padding: "0.3rem 0.6rem",
  borderRadius: "0.4rem",
  background: process.env.REACT_APP_MAIN_COLOR,
  color: "white",
  display: "inline-block",
  fontSize: "0.8rem",
  fontWeight: "bold",
  marginBottom: "1.5rem",
});

export const UsingPlanBox = styled(Box)({
  padding: "0.3rem 0.6rem",
  borderRadius: "0.4rem",
  border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
  color: process.env.REACT_APP_MAIN_COLOR,
  display: "inline-block",
  fontSize: "0.8rem",
  fontWeight: "bold",
  marginBottom: "1.5rem",
});

export const PriceFullBox = styled(Box)({
  marginBottom: "2.5rem",
});

export const PriceBox = styled(Box)({
  display: "flex",
  alignItems: "baseline",
  gap: "0.3rem",
});

export const PriceTypo = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
  height: "100%",
});

export const MonthlyTypo = styled(Typography)({
  fontSize: "0.8rem",
  fontWeight: "bold",
});

export const PriceDurationTypo = styled(Typography)({
  fontSize: "0.8rem",
});

export const PriceDescTypo = styled(Typography)({
  fontSize: "1rem",
  marginBottom: "2rem",
});

export const PurchaseButton = styled(Button)({
  backgroundColor: process.env.REACT_APP_MAIN_COLOR, // 배경색을 `${process.env.REACT_APP_MAIN_COLOR}`로 설정
  color: "#FFFFFF", // 텍스트 색상을 흰색으로 설정
  fontSize: "1rem",
  width: "100%",
  marginBottom: "1rem",
  "&:hover": {
    backgroundColor: "#3A1A7A", // 호버 시 배경색을 약간 어둡게
  },
});

export const CustomDivider = styled(Divider)({
  marginTop: "20px",
  marginBottom: "1.5rem",
});

export const FeaturesStack = styled(Stack)({
  gap: "10px",
});

export const FeatureStack = styled(Stack)({
  justifyContent: "space-between",
  alignItems: "center",
});

export const FeatureTypo = styled(Typography)({
  fontSize: "0.9rem",
});

export const FeatureIcon = styled(InfoOutlinedIcon)({
  color: "#8f8f8f",
  fontSize: "1.1rem",
});
