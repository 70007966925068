import React from "react";
import { useAtom } from "jotai";
import { pageListAtom, payTypeAtom } from "../Atoms/PlanTableAtom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BillingType } from "../Enum/BillingTypeEnum";
import { Subscription } from "../Components/PlanTable/Subscription";
import * as S from "../Components/PlanTable/styled";
import { Single } from "../Components/PlanTable/Single";
import { KeyboardArrowRight } from "@mui/icons-material";
import { paymentTypeAtom } from "../Atoms/PublicAtom";
import { CareerplannerSingle } from "../Components/PlanTable/CareerplannerSingle";

export function PlanTable() {
  const [payType, setPayType] = useAtom(payTypeAtom);
  const [pageList] = useAtom(pageListAtom);
  const [paymentType] = useAtom(paymentTypeAtom);

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        flexGrow: 1,
        background: "#efefef",
        minHeight: "calc(100vh - 56px)",
        boxSizing: "border-box",
      }}
      padding={5}
    >
      {paymentType === "dokgabi" ? (
        <>
          <Box
            sx={{
              width: "100%",
              alignItems: "end",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Button
              sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
              onClick={() => navigate("/refund")}
            >
              환불 규정 안내
              <KeyboardArrowRight />
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginBottom: "30px",
            }}
          >
            <S.ButtonContainer>
              {pageList.map((page, index) =>
                payType === page.value ? (
                  <S.CurrentPageButton
                    key={`page-mosigi-${index}`}
                    onClick={() => setPayType(page.value)}
                  >
                    {page.view_value}
                  </S.CurrentPageButton>
                ) : (
                  <S.ElsePageButton
                    key={`page-mosigi-${index}`}
                    onClick={() => setPayType(page.value)}
                  >
                    {page.view_value}
                  </S.ElsePageButton>
                )
              )}
            </S.ButtonContainer>
          </Box>
          {payType === BillingType.SUBSCRIPTION ? <Subscription /> : <Single />}
          <Divider sx={{ marginTop: "20px" }} />
          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ color: "#8f8f8f" }}>
              초과 사용량 단위별 금액 및 온보딩 서비스 금액 안내
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background: process.env.REACT_APP_MAIN_COLOR,
                      color: "white",
                    }}
                  >
                    <TableCell
                      sx={{
                        background: process.env.REACT_APP_MAIN_COLOR,
                        color: "white",
                      }}
                    >
                      항목
                    </TableCell>
                    <TableCell
                      sx={{
                        background: process.env.REACT_APP_MAIN_COLOR,
                        color: "white",
                      }}
                    >
                      금액
                    </TableCell>
                    <TableCell
                      colSpan={6}
                      sx={{
                        background: process.env.REACT_APP_MAIN_COLOR,
                        color: "white",
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>대화당 금액</TableCell>
                    <TableCell>₩10/대화</TableCell>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>문서 하나당 금액</TableCell>
                    <TableCell>₩0.45/문서</TableCell>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>온보딩 서비스(기술 지원)</TableCell>
                    <TableCell>30일</TableCell>
                    <TableCell>60일</TableCell>
                    <TableCell>90일</TableCell>
                    <TableCell>120일</TableCell>
                    <TableCell>150일</TableCell>
                    <TableCell>180일</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>60만원</TableCell>
                    <TableCell>120만원</TableCell>
                    <TableCell>180만원</TableCell>
                    <TableCell>240만원</TableCell>
                    <TableCell>300만원</TableCell>
                    <TableCell>360만원</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              alignItems: "end",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Button
              sx={{ color: "#06c25e" }}
              onClick={() => navigate("/refund")}
            >
              환불 규정 안내
              <KeyboardArrowRight />
            </Button>
          </Box>
          <CareerplannerSingle />
        </>
      )}
    </Box>
  );
}
