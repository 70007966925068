import React from "react";
import { useAtom } from "jotai";
import { Avatar, Button, Icon, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as S from "./styled";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
} from "../../../Atoms/PublicAtom";
import { ProfileMenu } from "./ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";

import {
  handleNoticeWhenServiceIsInDevelopAtom,
  handlePricingMenuOpenAtom,
  handleProfileMenuOpenAtom,
  setStateDrawerAtom,
} from "../../../ViewModels/HeaderViewModel";
import { PaymentMenu } from "./PaymentMenu";

export function Header() {
  const [user] = useAtom(dokgabiUserAtom);
  const [cpUser] = useAtom(careerplannerUserAtom);
  const [profileOpen, profileHandleClick] = useAtom(handleProfileMenuOpenAtom);
  const [pricingOpen, pricingHandleClick] = useAtom(handlePricingMenuOpenAtom);

  const [, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [, handleNoticeWhenServiceIsInDevelop] = useAtom(
    handleNoticeWhenServiceIsInDevelopAtom
  );

  const navigate = useNavigate();

  return (
    <S.HeaderContainer>
      {/* 모바일 환경일 시 */}
      <S.HeaderMobileContainer>
        <IconButton onClick={(e) => toggleDrawer(true, e)}>
          <MenuIcon />
        </IconButton>
      </S.HeaderMobileContainer>

      {/* 모바일 환경 아닐 시 */}
      <S.HeaderInnerContainer>
        <S.HeaderInnerInnerContainer gap="0">
          <IconButton onClick={(e) => toggleDrawer(true, e)}>
            <MenuIcon />
          </IconButton>
          <IconButton onClick={() => navigate("/")} sx={{ margin: "0 16px" }}>
            <Avatar alt="Remy Sharp" src={"/favicon.ico"} />
          </IconButton>
          <Button
            onClick={pricingHandleClick}
            size="small"
            aria-controls={pricingOpen ? "payment-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={pricingOpen ? "true" : undefined}
            // onClick={() => navigate("/")}
            // aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            요금제
          </Button>
          <Button
            onClick={handleNoticeWhenServiceIsInDevelop}
            aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            튜토리얼
          </Button>
        </S.HeaderInnerInnerContainer>

        <S.HeaderInnerInnerContainer gap="0px">
          {user || cpUser ? (
            <>
              <IconButton
                onClick={profileHandleClick} //   <IconButton // <>
                size="small"
                sx={{ ml: 2, margin: "0 16px" }}
                aria-controls={profileOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={profileOpen ? "true" : undefined}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    ""
                    // user?.image === ""
                    //   ? ""
                    //   : `${process.env.REACT_APP_DOKGABI_USER_MEDIA_ADDRESS}${user?.image}`
                  }
                />
              </IconButton>
            </>
          ) : (
            <S.SignupButton
              onClick={() =>
                (window.location.href =
                  process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS)
              }
            >
              Login
            </S.SignupButton>
          )}
        </S.HeaderInnerInnerContainer>
      </S.HeaderInnerContainer>

      <ProfileMenu />
      <PaymentMenu />
    </S.HeaderContainer>
  );
}
